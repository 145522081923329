<script lang="ts" setup>
import type { LinkColumnRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: LinkColumnRecord }>();

const localePath = useLocalePath();
const links = computed(() => props.data.linkItems.map(l => getLinkForType(l, localePath)));
</script>

<template>
  <div>
    <slot name="title" />

    <nav>
      <ul>
        <li v-for="link in links" :key="link.id">
          <slot name="item" :link="link" />
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped>

</style>
